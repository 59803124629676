/**
 * Extracts and parses URL parameters after the hash symbol.
 * @param {string} [url=window.location.href] - The URL to parse. Defaults to the current page URL.
 * @returns {Object} An object containing the parsed parameters and their count.
 */

const parseURLParameters = (url = window.location.href) => {
  const urlAfterHash = url.split('#')[1] || url;

  if (!urlAfterHash.includes('?')) return { size: 0 };

  const queryString = urlAfterHash.split('?')[1];
  const parameters = Object.fromEntries(new URLSearchParams(queryString));
  parameters.size = Object.keys(parameters).length;

  return parameters;
};

export default parseURLParameters;
