import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import FlightItem from './subcomponents/FlightItem';
import getCartSameFlightModalFooterText from '../helpers/getCartSameFlightModalFooterText';
import './styles.scss';

const CartSameFlightModalContent = ({ flightsWithSameDate, laymanMode, isQuoted }) => {
  const modalFooterText = getCartSameFlightModalFooterText(
    flightsWithSameDate.length - 1,
    laymanMode,
    isQuoted,
  );

  return (
    <div className="d-flex-column flex-gap-20 cart-same-flight-modal-content">
      <span>{I18n.t('cart.multiple_items.subtitle')}</span>
      <div className="cart-same-flight-modal-content__items">
        {flightsWithSameDate.map((flight, index) => (
          <FlightItem key={flight.id} flight={flight} itemNumber={index + 1} />
        ))}
      </div>
      <span>{modalFooterText}</span>
    </div>
  );
};

CartSameFlightModalContent.propTypes = {
  laymanMode: PropTypes.bool.isRequired,
  flightsWithSameDate: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isQuoted: PropTypes.bool.isRequired,
};

export default hot(module)(CartSameFlightModalContent);
