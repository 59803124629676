const updateQueryParam = (paramKey, paramValue) => {
  if (!paramKey || !paramValue) {
    return;
  }

  const regex = /(#\/[^?]*)\??([^#]*)/;
  const url = window.location.href;

  const updatedUrl = url.replace(regex, (match, path, queryParams) => {
    const params = new URLSearchParams(queryParams);
    params.set(paramKey, paramValue);

    return `${path}?${params.toString()}`;
  });

  window.history.replaceState({}, '', updatedUrl);
};

export default updateQueryParam;
