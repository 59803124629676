import urlTemplate from 'url-template';

function url(template, params) {
  const myParams = { ...params };
  if (myParams.format === undefined) {
    myParams.format = 'json';
  }
  return urlTemplate.parse(template).expand(myParams);
}

const callbackParamsTemplate = [
  'callback_params[layman]',
  'callback_params[order_id]',
  'callback_params[person_id]',
  'callback_params[all_traveler_ids]',
].join(',');

const injectCallbackParams = params => {
  const { callbackParams, ...myParams } = params;
  if (callbackParams) {
    Object.keys(callbackParams).forEach(key => {
      myParams[`callback_params[${key}]`] = callbackParams[key];
    });
  }
  return myParams;
};

const routes = {
  admin: {
    customizedReports: {
      multiple: params => url('/admin/customized_reports', params),
    },
    dropdownOptions: {
      list: params =>
        url('/admin/dropdown-options/{type}{?q,page,per_page}{&filterParams*}', params),
      show: params => url('/admin/dropdown-options/{type}/{id}', params),
    },
    accounting: {
      turnover: params => url('/admin/accounting/turnover{.format}{?years[]*}', params),
      profitLossKpi: {
        monthly: params => url('/admin/accounting/profit_loss_kpi_monthly{.format}', params),
        yearly: params => url('/admin/accounting/profit_loss_kpi_yearly{.format}', params),
      },
      paymentDelayKpi: params => url('/admin/accounting/payment_delay_kpi{.format}', params),
      transaction: {
        list: params =>
          url(
            '/admin/accounting/transactions{.format}{?q,page,column,direction,is_based_on_and_operation,transaction_date,creator_ids[]*,max_amount,min_amount,credit_account_ids[]*,debit_account_ids[]*,organization_ids[]*,person_ids[]*,order_ids[]*,invoice_ids[]*,channel_invoice_ids[]*}',
            params,
          ),
        creators: params => url('/admin/accounting/transactions/creators{.format}', params),
        debitAccounts: params =>
          url('/admin/accounting/transactions/debit_accounts{.format}', params),
        creditAccounts: params =>
          url('/admin/accounting/transactions/credit_accounts{.format}', params),
        balanceSheet: params =>
          url(
            '/admin/accounting/transactions/balance_sheet{.format}{?show_all_accounts,start_date,end_date}',
            params,
          ),
        incomeStatement: params =>
          url(
            '/admin/accounting/transactions/income_statement{.format}{?show_all_accounts,transaction_year[]*}',
            params,
          ),
        show: params => url('/admin/accounting/transactions/{transactionId}{.format}', params),
        create: params => url('/admin/accounting/transactions{.format}', params),
        update: params => url('/admin/accounting/transactions/{transactionId}{.format}', params),
      },
      accounts: {
        list: params =>
          url(
            '/admin/accounting/accounts{.format}{?q,per_page,type_ids[]*,column,direction,page,start_date,end_date}',
            params,
          ),
        create: params => url('/admin/accounting/accounts{.format}', params),
        update: params => url('/admin/accounting/accounts/{accountId}{.format}', params),
        delete: params => url('/admin/accounting/accounts/{accountId}{.format}', params),
        categories: params => url('/admin/accounting/accounts/account_categories{.format}', params),
      },
      defaultAccounts: {
        fallback: params =>
          url('/admin/accounting/default_accounts/fallback_accounts{.format}', params),
        earning: params =>
          url('/admin/accounting/default_accounts/earning_accounts{.format}{?q,page}', params),
        multiple: params => url('/admin/accounting/default_accounts{.format}', params),
        single: params => url('/admin/accounting/default_accounts/{id}{.format}', params),
      },
      settings: {
        account_settings: params => url('/admin/settings/account_settings{.format}', params),
        update: params => url('/admin/settings{.format}', params),
      },
      channelInvoices: {
        list: params =>
          url(
            '/admin/channel_invoices{.format}{?q,page,state,column,direction,created_at_starts,created_at_ends,due_date_starts,due_date_ends,paid_at_starts,paid_at_ends,cancelled_at_starts,cancelled_at_ends,filter}',
            params,
          ),
        create: params => url('/admin/channel_invoices{.format}', params),
        update: params => url('/admin/channel_invoices/{invoiceId}{.format}', params),
      },
      accountsPayableKpi: params => url('/admin/accounting/accounts_payable_kpi{.format}', params),
      accountsReceivableKpi: params =>
        url('/admin/accounting/accounts_receivable_kpi{.format}', params),
    },
    cartPage: params => url('/admin/wtag-reservations/new#/carts/{id}', params),
    hotelPage: () => url('/admin/wtag-reservations/new#/hotels'),
    orderMuseum: params =>
      url(
        '/admin/orders/museum{.format}{?q,page,person_id,organization_id,scope,column,direction,per_page,on_travel,start_date,end_date,travel_filter}',
        params,
      ),
    orders: params =>
      url(
        '/admin/orders{.format}{?q,page,person_id,organization_id,scope,column,direction,per_page,on_travel,start_date,end_date,travel_filter,booked_on}',
        params,
      ),
    orderCreate: params => url('/admin/orders{.format}', params),
    ordersUpdate: params => url('/admin/orders/{orderId}{.format}', params),
    orderInvoices: params =>
      url(
        '/admin/orders/{relatedModelId}/invoices{.format}{?q,tab,page,per_page,column,direction}',
        params,
      ),
    order: params => url('/admin/orders/{id}{.format}', params),
    welltravelWalletDetails: () => url(`/api/welltravel_wallet`),
    orderHistories: params => url('/admin/orders/{orderId}/histories{.format}', params),
    items: params => url('/admin/orders/{id}/items{.format}', params),
    newOrder: params =>
      url('/admin/orders/new{?booking[supplier],booking[account],booking[id]}', params),
    customizePdf: params => url('/admin/orders/{id}/customize_pdf{.format}{?column}', params),
    orderItems: {
      list: params =>
        url(
          '/admin/order_items{.format}{?page,created_at_starts,created_at_ends,organization_id[]*}',
          params,
        ),
      show: params => url('/admin/order_items/{id}{.format}', params),
      create: params => url('/admin/orders/{order_id}/order_items{.format}', params),
      edit: params =>
        url('/admin/orders/{orderId}/order_items/{orderItemId}/edit{.format}', params),
      update: params => url('/admin/orders/{orderId}/order_items/{orderItemId}{.format}', params),
      addProduct: params => url('/admin/orders/{orderId}/order_items/products{.format}', params),
      voucherFormats: params =>
        url('/admin/orders/{order_id}/order_items/voucher_formats{.format}', params),
      paymentTypes: params =>
        url('/admin/orders/{order_id}/order_items/payment_types{.format}', params),
      statuses: params => url('/admin/orders/{order_id}/order_items/statuses{.format}', params),
      groupLabels: params =>
        url('/admin/orders/{order_id}/order_items/group_labels{.format}', params),
      updatePaymentMethod: params =>
        url('/admin/orders/{orderId}/order_items/{id}/update_payment_method{.format}', params),
      addFop: params => url('/admin/orders/{orderId}/order_items/{id}/add_fop{.format}', params),
      addMaskedCreditCard: params =>
        url('/admin/orders/{orderId}/order_items/{id}/add_masked_credit_card{.format}', params),
      updateBookingCache: params =>
        url('/admin/orders/{orderId}/order_items/{id}/update_booking_cache{.format}', params),
      cancel: params => url('/admin/orders/{orderId}/order_items/{id}/cancel', params),
      toBeInvoiced: params =>
        url(
          '/admin/order_items/to_be_invoiced{.format}{?q,state,page,per_page,column,direction,departure_starts_at,departure_ends_at,arrival_starts_at,arrival_ends_at,booking_date_starts_at,booking_date_ends_at,owner_ids[]*,traveller_ids[]*,organization_ids[]*,channels[]*}',
          params,
        ),
      subItems: params => url('/admin/order_items/{id}/sub_items{.format}', params),
      voidTicket: params => url('/admin/orders/{orderId}/order_items/{id}/void', params),
    },
    orderDistinctTravellers: params =>
      url('/admin/orders/{orderId}/distinct_travellers{.format}', params),
    wifiAvailability: params => url('/admin/orders/wifi_availabilities{.format}', params),
    boardTypes: params => url('/admin/orders/board_types{.format}', params),
    offlineBookings: {
      channels: params =>
        url(
          '/admin/orders/{order_id}/offline_bookings/{itemType}s/filtered_channel_list{.format}',
          params,
        ),
      suppliers: params =>
        url('/admin/suppliers/supplier_options{.format}?supplier_type={itemType}&q={q}', params),
      travelers: {
        list: params => url('/admin/travelers{.format}{?order_item_id}', params),
        create: params => url('/admin/travelers{.format}', params),
        update: params => url('/admin/travelers/{id}{.format}', params),
        delete: params => url('/admin/travelers/{id}{.format}', params),
      },
      car: {
        create: params => url('/admin/orders/{order_id}/offline_bookings/cars{.format}', params),
        update: params => url('/admin/offline_bookings/cars/{orderItemId}{.format}', params),
        edit: params => url('/admin/offline_bookings/cars/{orderItemId}/edit{.format}', params),
        vendorCodesOptions: params =>
          url(
            '/admin/orders/{order_id}/offline_bookings/cars/vendor_codes_options{.format}',
            params,
          ),
        statuses: params =>
          url('/admin/orders/{order_id}/offline_bookings/cars/statuses{.format}', params),
        insurances: params =>
          url('/admin/orders/{order_id}/offline_bookings/cars/insurances{.format}', params),
        carCategories: params =>
          url('/admin/orders/{order_id}/offline_bookings/cars/car_categories{.format}', params),
      },
      event: {
        create: params => url('/admin/orders/{orderId}/offline_bookings/events{.format}', params),
        show: params => url('/admin/offline_bookings/events/{orderItemId}{.format}', params),
        update: params => url('/admin/offline_bookings/events/{orderItemId}{.format}', params),
      },
      transfer: {
        create: params =>
          url('/admin/orders/{orderId}/offline_bookings/transfers{.format}', params),
        show: params => url('/admin/offline_bookings/transfers/{orderItemId}{.format}', params),
        update: params => url('/admin/offline_bookings/transfers/{orderItemId}{.format}', params),
      },
      hotel: {
        roomsList: params =>
          url('/admin/offline_bookings/hotels/{hotelId}/hotel_rooms{.format}', params),
        createRoom: params =>
          url('/admin/offline_bookings/hotels/{hotelId}/hotel_rooms{.format}', params),
        updateRoom: params => url('/admin/offline_bookings/hotel_rooms/{roomId}{.format}', params),
        deleteRoom: params => url('/admin/offline_bookings/hotel_rooms/{roomId}{.format}', params),
        create: params => url('/admin/orders/{orderId}/offline_bookings/hotels{.format}', params),
        show: params => url('/admin/offline_bookings/hotels/{orderItemId}{.format}', params),
        update: params => url('/admin/offline_bookings/hotels/{orderItemId}{.format}', params),
        statuses: params =>
          url('/admin/orders/{order_id}/offline_bookings/hotels/statuses{.format}', params),
      },
      cruise: {
        create: params => url('/admin/orders/{orderId}/offline_bookings/cruises{.format}', params),
        show: params => url('/admin/offline_bookings/cruises/{orderItemId}{.format}', params),
        update: params => url('/admin/offline_bookings/cruises/{orderItemId}{.format}', params),
      },
      flight: {
        show: params => url('/admin/offline_bookings/flights/{orderItemId}{.format}', params),
        service: {
          create: params =>
            url('/admin/offline_bookings/flights/{flightId}/flight_services{.format}', params),
          update: params =>
            url('/admin/offline_bookings/flight_services/{serviceId}{.format}', params),
          delete: params =>
            url('/admin/offline_bookings/flight_services/{serviceId}{.format}', params),
        },
        trip: {
          create: params =>
            url('/admin/offline_bookings/flights/{flightId}/flight_trips{.format}', params),
          delete: params => url('/admin/offline_bookings/flight_trips/{tripId}{.format}', params),
          edit: params => url('/admin/offline_bookings/flight_trips/{tripId}/edit', params),
          show: params => url('/admin/offline_bookings/flight_trips/{tripId}{.format}', params),
          update: params => url('/admin/offline_bookings/flight_trips/{tripId}{.format}', params),
        },
        segment: {
          create: params =>
            url('/admin/offline_bookings/flight_trips/{tripId}/flight_segments{.format}', params),
          delete: params =>
            url('/admin/offline_bookings/flight_segments/{segmentId}{.format}', params),
          edit: params => url('/admin/offline_bookings/flight_segments/{segmentId}/edit', params),
          show: params =>
            url('/admin/offline_bookings/flight_segments/{segmentId}{.format}', params),
          update: params =>
            url('/admin/offline_bookings/flight_segments/{segmentId}{.format}', params),
        },
        seat: {
          create: params =>
            url(
              '/admin/offline_bookings/flight_segments/{segmentId}/flight_seats{.format}',
              params,
            ),
          delete: params => url('/admin/offline_bookings/flight_seats/{seatId}{.format}', params),
          update: params => url('/admin/offline_bookings/flight_seats/{seatId}{.format}', params),
        },
        ticket: {
          create: params =>
            url(
              '/admin/offline_bookings/flight_segments/{segmentId}/flight_tickets{.format}',
              params,
            ),
          delete: params =>
            url('/admin/offline_bookings/flight_tickets/{ticketId}{.format}', params),
          update: params =>
            url('/admin/offline_bookings/flight_tickets/{ticketId}{.format}', params),
        },
        create: params => url('/admin/orders/{orderId}/offline_bookings/flights{.format}', params),
        cateringOptions: params =>
          url('/admin/orders/{orderId}/offline_bookings/flights/catering_options{.format}', params),
        fareTypeOptions: params =>
          url(
            '/admin/orders/{orderId}/offline_bookings/flights/fare_type_options{.format}',
            params,
          ),
        statusOptions: params =>
          url('/admin/orders/{orderId}/offline_bookings/flights/status_options{.format}', params),
        travellerOptions: params =>
          url(
            '/admin/orders/{orderId}/offline_bookings/flights/traveller_options{.format}',
            params,
          ),
        cabinClassOptions: params =>
          url(
            '/admin/orders/{orderId}/offline_bookings/flights/cabin_class_options{.format}',
            params,
          ),
        update: params => url('/admin/offline_bookings/flights/{orderItemId}{.format}', params),
      },
    },
    orderComments: {
      create: params => url('/admin/orders/{orderId}/order_comments{.format}', params),
      list: params =>
        url('/admin/orders/{orderId}/order_comments{.format}{?created_at,user_ids[]*}', params),
      update: params => url('/admin/orders/{orderId}/order_comments/{id}{.format}', params),
    },
    orderApprovers: params => url('/admin/orders/{orderId}/approvers{.format}', params),
    orderTravellers: params => url('/admin/orders/{orderId}/travellers{.format}', params),
    orderFees: params => url('/admin/orders/{id}/fees{.format}', params),
    interests: {
      list: params => url('/admin/interests{.format}{?page,per_page}', params),
      create: params => url('/admin/interests{.format}', params),
      update: params => url('/admin/interests/{id}{.format}', params),
      delete: params => url('/admin/interests/{id}{.format}', params),
      options: params => url('/admin/interests/options{.format}', params),
    },
    orderAccountings: {
      list: params => url('/admin/orders/{orderId}/cost_centers{.format}{?page}', params),
      create: params => url('/admin/orders/{orderId}/cost_centers{.format}', params),
      edit: params =>
        url('/admin/orders/{orderId}/cost_centers/{accountingId}/edit{.format}', params),
      update: params => url('/admin/orders/{orderId}/cost_centers/{accountingId}{.format}', params),
      delete: params => url('/admin/orders/{orderId}/cost_centers/{accountingId}{.format}', params),
    },
    orderDocuments: {
      list: params =>
        url('/admin/orders/{orderId}/attachments{.format}{?q,page,column,direction}', params),
      create: params => url('/admin/orders/{orderId}/attachments{.format}', params),
      show: params => url('/admin/orders/{orderId}/attachments/{attachmentId}{.format}', params),
      update: params => url('/admin/orders/{orderId}/attachments/{attachmentId}{.format}', params),
      delete: params => url('/admin/orders/{orderId}/attachments/{attachmentId}{.format}', params),
    },

    metaSearch: {
      country: params => url('/admin/meta_search/country{.format}{?code}', params),
      features: params => url('/admin/meta_search/features{.format}', params),
      carRentalCompany: params =>
        url('/admin/meta_search/car_rental_company{.format}{?code}', params),
    },
    orderTodos: {
      list: params => url('/admin/orders/{orderId}/todos{.format}{?done}', params),
      show: params => url('/admin/orders/{orderId}/todos/{todoId}{.format}', params),
      create: params => url('/admin/orders/{orderId}/todos{.format}', params),
      update: params => url('/admin/orders/{orderId}/todos/{todoId}{.format}', params),
      delete: params => url('/admin/orders/{orderId}/todos/{todoId}{.format}', params),
    },
    todos: {
      priorities: params => url('/admin/todos/priorities{.format}', params),
      my_tasks: params => url('/admin/todos{.format}{?done,q,priorities[]*,dueDates[]*}', params),
      team_tasks: params =>
        url('/admin/todos/team_tasks{.format}{?done,q,priorities[]*,dueDates[]*}', params),
      unassigned_tasks: params =>
        url('/admin/todos/unassigned_tasks{.format}{?done,q,priorities[]*,dueDates[]*}', params),
      all_tasks: params =>
        url('/admin/todos/all_tasks{.format}{?done,q,priorities[]*,dueDates[]*}', params),
    },
    todoComments: {
      create: params =>
        url('/admin/orders/{orderId}/todos/{todoId}/todo_comments{.format}', params),
      list: params =>
        url(
          '/admin/orders/{orderId}/todos/{todoId}/todo_comments{.format}{?created_at,user_ids[]*}',
          params,
        ),
      update: params =>
        url('/admin/orders/{orderId}/todos/{todoId}/todo_comments/{id}{.format}', params),
    },

    users: {
      list: params => url('/admin/users{.format}{?q,per_page}', params),
      notificationPreference: params =>
        url('/admin/users/{userId}/notification_preferences{.format}', params),
      update: params => url('/admin/users/{userId}{.format}', params),
    },

    people: {
      update: params => url('/admin/people/{personId}{.format}', params),
      histories: params => url('/admin/people/{personId}/histories{.format}', params),
      reactivate: params => url('/admin/people/{personId}/reactivate{.format}', params),
      updateAvatar: params => url('/admin/people/{personId}/avatar{.format}', params),
      create: params => url('/admin/people{.format}', params),
      show: params => url('/admin/people/{personId}{.format}{?tab}', params),
      invoices: params =>
        url('/admin/people/{relatedModelId}/invoices{.format}{?q,page,column,direction}', params),
      travellerList: params => url('/admin/people/traveller_list{.format}', params),
      legalPaperAcceptances: params =>
        url(
          '/admin/people/{personId}/legal_paper_acceptances{.format}{?q,page,tab,column,direction}',
          params,
        ),
      list: params =>
        url(
          '/admin/people{.format}{?q,first_name,middle_name,last_name,birthday,salutation,emails[]*,organization_ids[]*,person_ids[]*,page,column,direction,start_date,end_date,filter,archived}',
          params,
        ),
      availableConsultants: params => url('/admin/people/available_consultants', params),
      countryOptions: params => url('/admin/people/country_options', params),
      nationalityOptions: params => url('/admin/people/nationality_options', params),
      travelArrangers: params =>
        url('/admin/people/{personId}/travel_arrangers{.format}{?page}', params),
      travelArrangementClients: params =>
        url('/admin/people/{personId}/travel_arrangement_clients{.format}{?page}', params),
      colleagues: params => url('/admin/people/{personId}/colleagues{.format}', params),
      refund: params => url('/admin/people/{personId}/refunds{.format}', params),
      comments: {
        create: params => url('/admin/people/{personId}/customer_comments{.format}', params),
        list: params =>
          url(
            '/admin/people/{personId}/customer_comments{.format}{?created_at,user_ids[]*}',
            params,
          ),
        update: params => url('/admin/people/{personId}/customer_comments/{id}{.format}', params),
      },
      accountingTypes: {
        list: params =>
          url(
            '/admin/people/{personId}/accounting_types{.format}{?q,page,column,direction}',
            params,
          ),
        create: params => url('/admin/people/{personId}/accounting_types{.format}', params),
        update: params =>
          url('/admin/people/{personId}/accounting_types/{accountingTypeId}{.format}', params),
      },
      accountingTypeValues: {
        list: params =>
          url(
            '/admin/people/{personId}/accounting_types/{accountingTypeId}/cost_centers{.format}{?q,page,column,direction}',
            params,
          ),
        create: params =>
          url(
            '/admin/people/{personId}/accounting_types/{accountingTypeId}/cost_centers{.format}',
            params,
          ),
        update: params =>
          url(
            '/admin/people/{personId}/accounting_types/{accountingTypeId}/cost_centers/{id}{.format}',
            params,
          ),
      },
      travelArrangerSettings: {
        list: params => url('/admin/people/{personId}/travel_arranger_settings{.format}', params),
        create: params => url('/admin/people/{personId}/travel_arranger_settings{.format}', params),
        availableArrangers: params =>
          url(
            '/admin/people/{personId}/travel_arranger_settings/available_travel_arrangers{.format}{?q}',
            params,
          ),
        delete: params =>
          url('/admin/people/{personId}/travel_arranger_settings/{id}{.format}', params),
      },
      levels: {
        list: params =>
          url('/admin/people/{personId}/levels{.format}{?q,page,column,direction}', params),
        update: params => url('/admin/people/{personId}/levels/{levelId}{.format}', params),
        delete: params => url('/admin/people/{personId}/levels/{levelId}{.format}', params),
      },
      emergencyContacts: {
        create: params => url('/admin/people/{personId}/emergency_contacts{.format}', params),
        list: params => url('/admin/people/{personId}/emergency_contacts{.format}', params),
        update: params =>
          url('/admin/people/{personId}/emergency_contacts/{emergencyContactId}{.format}', params),
        delete: params =>
          url('/admin/people/{personId}/emergency_contacts/{emergencyContactId}{.format}', params),
      },
      budgets: {
        list: params =>
          url('/admin/people/{personId}/budgets{.format}{?q,page,column,direction}', params),
        new: params => url('/admin/people/{personId}/budgets/new{.format}', params),
        create: params => url('/admin/people/{personId}/budgets{.format}', params),
        update: params => url('/admin/people/{personId}/budgets/{id}{.format}', params),
        edit: params => url('/admin/people/{personId}/budgets/{id}/edit{.format}', params),
        show: params => url('/admin/people/{personId}/budgets/{id}{.format}', params),
        accountingTypes: params =>
          url(
            '/admin/people/{personId}/budgets/accounting_types{.format}{?costcenterable_type}',
            params,
          ),
        costCenters: params =>
          url(
            '/admin/people/{personId}/budgets/cost_centers{.format}{?accounting_type_id}',
            params,
          ),
      },
      approvalRequests: {
        list: params =>
          url('/admin/people/{personId}/approval_requests{.format}{?page,previous}', params),
      },
      approvalConfirmations: {
        list: params =>
          url('/admin/people/{personId}/approval_confirmations{.format}{?page,previous}', params),
      },
      carLoyaltyPrograms: {
        list: params => url('/admin/people/{personId}/car_loyalty_programs{.format}', params),
        create: params => url('/admin/people/{personId}/car_loyalty_programs{.format}', params),
        update: params =>
          url('/admin/people/{personId}/car_loyalty_programs/{id}{.format}', params),
        delete: params =>
          url('/admin/people/{personId}/car_loyalty_programs/{id}{.format}', params),
      },
      hotelLoyaltyPrograms: {
        list: params => url('/admin/people/{personId}/hotel_loyalty_programs{.format}', params),
        create: params => url('/admin/people/{personId}/hotel_loyalty_programs{.format}', params),
        update: params =>
          url('/admin/people/{personId}/hotel_loyalty_programs/{id}{.format}', params),
        delete: params =>
          url('/admin/people/{personId}/hotel_loyalty_programs/{id}{.format}', params),
      },
    },
    messages: {
      list: params =>
        url('/admin/messages{.format}{?q,page,column,direction,order_id,person_id}', params),
      show: params => url('/admin/messages/{messageId}{.format}', params),
    },
    personalQueue: {
      create: params => url('/admin/personal_queues{.format}', params),
      list: params => url('/admin/personal_queues{.format}', params),
      update: params => url('/admin/personal_queues/{personalQueueId}{.format}', params),
    },
    identificationDocuments: {
      create: params => url('/admin/identification_documents{.format}{?person_id}', params),
      list: params => url('/admin/identification_documents{.format}{?person_id}', params),
      update: params =>
        url('/admin/identification_documents/{documentId}{.format}{?person_id}', params),
      delete: params =>
        url('/admin/identification_documents/{documentId}{.format}{?person_id}', params),
    },
    visaNumbers: {
      create: params => url('/admin/visa_numbers{.format}{?person_id}', params),
      list: params => url('/admin/visa_numbers{.format}{?person_id}', params),
      update: params => url('/admin/visa_numbers/{visaNumberID}{.format}{?person_id}', params),
      delete: params => url('/admin/visa_numbers/{visaNumberID}{.format}{?person_id}', params),
    },
    socialMedia: {
      create: params =>
        url('/admin/social_media{.format}{?person_id,organization_id,ibe_setting_id}', params),
      list: params =>
        url('/admin/social_media{.format}{?person_id,organization_id,ibe_setting_id}', params),
      update: params =>
        url(
          '/admin/social_media/{socialMediaID}{.format}{?person_id,organization_id,ibe_setting_id}',
          params,
        ),
      delete: params =>
        url(
          '/admin/social_media/{socialMediaID}{.format}{?person_id,organization_id,ibe_setting_id}',
          params,
        ),
    },
    deals: {
      edit: params => url('/admin/settings/ibe/deals/{dealId}/edit{.format}', params),
      update: params => url('/admin/settings/ibe/deals/{dealId}{.format}', params),
      images: params => url('/admin/settings/ibe/deals/{dealId}/images{.format}', params),
      organizations: params =>
        url('/admin/settings/ibe/deals/{dealId}/organizations{.format}', params),
      interests: params => url('/admin/settings/ibe/deals/{dealId}/interests{.format}', params),
      index: params =>
        url(
          '/admin/deals{.format}{?q,name,teaser,column,direction,page,person_id,archived,filter}',
          params,
        ),
    },
    frequentFlyerNumbers: {
      create: params => url('/admin/frequent_flyer_numbers{.format}{?person_id}', params),
      list: params => url('/admin/frequent_flyer_numbers{.format}{?person_id}', params),
      update: params => url('/admin/frequent_flyer_numbers/{id}{.format}{?person_id}', params),
      delete: params => url('/admin/frequent_flyer_numbers/{id}{.format}{?person_id}', params),
    },
    carts: {
      list: params =>
        url(
          '/admin/carts{.format}{?shared,q,page,column,direction,state,shared_at_start,shared_at_end,owner_ids[]*,sharer_user_ids[]*,sharer_person_ids[]*}',
          params,
        ),
      travelers: params => url('/admin/carts/travelers{.format}{?shared,state,q}', params),
      sharerTravelers: params =>
        url('/admin/carts/sharer_travelers{.format}{?shared,state,q}', params),
      sharerUsers: params => url('/admin/carts/sharer_users{.format}{?shared,state,q}', params),
    },
    queueManagement: {
      list: params =>
        url('/admin/queue_management{.format}{?page,per_page,channel,account,queue}', params),
    },
    bookingImport: {
      create: params => url('/admin/booking_import{.format}', params),
    },
    credentials: {
      list: params => url('/admin/credentials{.format}{?is_group_by_channel}', params),
      edit: params => url('/admin/credentials/{credentialId}/edit{.format}', params),
      update: params => url('/admin/credentials/{credentialId}{.format}', params),
      organizationQueues: params =>
        url('/admin/credentials/{credentialId}/organization_queues{.format}', params),
      channels: params =>
        url('/admin/credentials/channels{.format}{?booking_import_supported}', params),
      accounts: params =>
        url('/admin/credentials/{credentialId}/credential_accounts{.format}', params),
      queueSupportedChannels: params =>
        url('/admin/credentials/queue_supported_channels{.format}', params),
    },
    vouchers: {
      list: params =>
        url(
          '/admin/vouchers{.format}{?q,title,destination,page,column,direction,voucher_state,updated_at}',
          params,
        ),
      use: params => url('/admin/vouchers/{voucherId}/use{.format}', params),
    },
    bookings: {
      refresh: params => url('/admin/bookings/{id}{.format}', params),
      show: params =>
        url('/admin/queue_management/{channel}/{account}/{identifier}{.format}', params),
      destroy: params => url('/admin/bookings/{id}{.format}', params),
      fetchUpdatedBooking: params =>
        url('/admin/bookings/{id}/fetch_updated_booking{.format}', params),
      tickets: {
        create: params => url('/admin/bookings/{id}/tickets', params),
        destroy: params => url('/admin/bookings/{id}/tickets', params),
      },
      addNewFare: params => url('/admin/bookings/{bookingId}/tickets/add_new_fare_price{.format}', params),
    },
    quoteItem: {
      refresh: params => url('/admin/quote_items/{id}', params),
    },
    organizations: {
      list: params =>
        url(
          '/admin/organizations{.format}{?q,id,name,page,column,direction,archived,start_date,end_date,filter,except_organization_ids[]*}',
          params,
        ),
      show: params => url('/admin/organizations/{organizationID}{.format}', params),
      updateAvatar: params => url('/admin/organizations/{organizationID}/avatar{.format}', params),
      invoices: params =>
        url(
          '/admin/organizations/{relatedModelId}/invoices{.format}{?q,page,column,direction}',
          params,
        ),
      histories: params => url('/admin/organizations/{organizationId}/histories{.format}', params),
      travelers: params =>
        url('/admin/organizations/{organizationId}/travelers{.format}{?q}', params),
      create: params => url('/admin/organizations', params),
      update: params => url('/admin/organizations/{organizationID}{.format}', params),
      reactivate: params =>
        url('/admin/organizations/{organizationID}/reactivate{.format}', params),
      refund: params => url('/admin/organizations/{organizationId}/refunds{.format}', params),
      comments: {
        create: params =>
          url('/admin/organizations/{organizationId}/customer_comments{.format}', params),
        list: params =>
          url(
            '/admin/organizations/{organizationId}/customer_comments{.format}{?created_at,user_ids[]*}',
            params,
          ),
        update: params =>
          url('/admin/organizations/{organizationId}/customer_comments/{id}{.format}', params),
      },
      budgets: {
        list: params =>
          url(
            '/admin/organizations/{organizationID}/budgets{.format}{?q,page,column,direction}',
            params,
          ),
        create: params => url('/admin/organizations/{organizationId}/budgets{.format}', params),
        update: params =>
          url('/admin/organizations/{organizationId}/budgets/{id}{.format}', params),
        edit: params =>
          url('/admin/organizations/{organizationId}/budgets/{id}/edit{.format}', params),
        show: params => url('/admin/organizations/{organizationId}/budgets/{id}{.format}', params),
        costCenters: params =>
          url(
            '/admin/organizations/{organizationId}/budgets/cost_centers{.format}{?accounting_type_id}',
            params,
          ),
        accountingTypes: params =>
          url(
            '/admin/organizations/{organizationId}/budgets/accounting_types{.format}{?costcenterable_type}',
            params,
          ),
      },
      consultants: params => url('/admin/organizations/available_consultants{.format}', params),
      branches: {
        create: params =>
          url('/admin/organizations/{organizationId}/organization_branches{.format}', params),
        update: params =>
          url('/admin/organizations/{organizationId}/organization_branches/{id}{.format}', params),
        destroy: params =>
          url('/admin/organizations/{organizationId}/organization_branches/{id}{.format}', params),
      },
      levels: {
        list: params =>
          url(
            '/admin/organizations/{organizationId}/levels{.format}{?q,page,column,direction,created_at,updated_at}',
            params,
          ),
        create: params => url('/admin/organizations/{organizationId}/levels{.format}', params),
        destroy: params =>
          url('/admin/organizations/{organizationId}/levels/{id}{.format}', params),
        update: params => url('/admin/organizations/{organizationId}/levels/{id}{.format}', params),
        associatedTravellers: params =>
          url(
            '/admin/organizations/{organizationId}/levels/{id}/associated_travellers{.format}',
            params,
          ),
        travellers: {
          list: params =>
            url(
              '/admin/organizations/{organizationId}/levels/{id}/travellers{.format}{?q,page,tab,column,direction}',
              params,
            ),
          create: params =>
            url(
              '/admin/organizations/{organizationId}/levels/{levelId}/travellers{.format}{?with_all_persons,person_ids[]*}',
              params,
            ),
          fetch: params =>
            url(
              '/admin/organizations/{organizationId}/levels/{levelId}/travellers/new{.format}',
              params,
            ),
          remove: params =>
            url(
              '/admin/organizations/{organizationId}/levels/{levelId}/travellers/personId{.format}{?person_id}',
              params,
            ),
        },
      },
      policies: {
        create: params =>
          url('/admin/organizations/{organizationId}/levels/{levelId}/policies{.format}', params),
        edit: params =>
          url(
            '/admin/organizations/{organizationId}/levels/{levelId}/policies/{policyId}/edit{.format}',
            params,
          ),
        update: params =>
          url(
            '/admin/organizations/{organizationId}/levels/{levelId}/policies/{policyId}{.format}',
            params,
          ),
        list: params =>
          url(
            '/admin/organizations/{organizationId}/levels/{levelId}/policies{.format}{?q,page,column,direction}',
            params,
          ),
      },
      positions: {
        list: params =>
          url(
            '/admin/organizations/{organizationId}/positions{.format}{?q,created_at,updated_at,page,column,direction}',
            params,
          ),
        create: params => url('/admin/organizations/{organizationId}/positions{.format}', params),
        update: params =>
          url('/admin/organizations/{organizationId}/positions/{id}{.format}', params),
        associatedTravellers: params =>
          url(
            '/admin/organizations/{organizationId}/positions/{id}/associated_travellers{.format}',
            params,
          ),
        delete: params =>
          url('/admin/organizations/{organizationId}/positions/{id}{.format}', params),
        travellers: {
          fetch: params =>
            url(
              '/admin/organizations/{organizationId}/positions/{positionId}/travellers/new{.format}',
              params,
            ),
          create: params =>
            url(
              '/admin/organizations/{organizationId}/positions/{positionId}/travellers{.format}{?with_all_persons,person_ids[]*}',
              params,
            ),
        },
      },
      cost_centers: {
        list: params => url('/admin/organizations/{organizationId}/cost_centers{.format}', params),
      },
      accountingTypes: {
        list: params =>
          url(
            '/admin/organizations/{organizationId}/accounting_types{.format}{?q,page,column,direction}',
            params,
          ),
        create: params =>
          url('/admin/organizations/{organizationId}/accounting_types{.format}', params),
        update: params =>
          url(
            '/admin/organizations/{organizationId}/accounting_types/{accountingTypeId}{.format}',
            params,
          ),
      },
      accountingTypeValues: {
        list: params =>
          url(
            '/admin/organizations/{organizationId}/accounting_types/{accountingTypeId}/cost_centers{.format}{?q,page,column,direction}',
            params,
          ),
        create: params =>
          url(
            '/admin/organizations/{organizationId}/accounting_types/{accountingTypeId}/cost_centers{.format}',
            params,
          ),
        update: params =>
          url(
            '/admin/organizations/{organizationId}/accounting_types/{accountingTypeId}/cost_centers/{id}{.format}',
            params,
          ),
      },
    },
    phoneNumbers: {
      create: params => url('/admin/phone_numbers{.format}', params),
      update: params => url('/admin/phone_numbers/{phoneNumberId}{.format}', params),
      delete: params => url('/admin/phone_numbers/{phoneNumberId}{.format}', params),
    },
    travellerAddresses: {
      create: params => url('/admin/traveler_addresses{.format}{?person_id}', params),
      update: params => url('/admin/traveler_addresses/{addressId}{.format}{?person_id}', params),
      delete: params => url('/admin/traveler_addresses/{addressId}{.format}{?person_id}', params),
    },
    redemptionVouchers: {
      group: {
        list: params =>
          url(
            '/admin/redemption_voucher_groups{.format}{?q,page,column,direction,valid_until,created_at}',
            params,
          ),
        single: params =>
          url('/admin/redemption_voucher_groups/{id}{.format}{?q,tab,page}', params),
        activateUnused: params =>
          url('/admin/redemption_voucher_groups/{id}/activate_unused', params),
        deactivateUnused: params =>
          url('/admin/redemption_voucher_groups/{id}/deactivate_unused', params),
      },
      campaign: {
        list: params =>
          url('/admin/redemption_voucher_campaigns{.format}{?q,tab,page,per_page}', params),
        single: params => url('/admin/redemption_voucher_campaigns/{id}{.format}', params),
        activate: params =>
          url('/admin/redemption_voucher_campaigns/{id}/activate{.format}', params),
        archive: params => url('/admin/redemption_voucher_campaigns/{id}/archive{.format}', params),
      },
      single: params => url('/admin/redemption_vouchers/{id}{.format}', params),
    },
    corporateCodes: {
      list: params => url('/admin/corporate_codes{.format}{?q,page,organization_id}', params),
      new: params => url('/admin/corporate_codes/new{.format}{?organization_id}', params),
      create: params => url('/admin/corporate_codes{.format}', params),
      show: params => url('/admin/corporate_codes/{id}{.format}', params),
      update: params => url('/admin/corporate_codes/{id}{.format}', params),
    },
    lineItems: {
      show: params => url('/admin/line_items/{id}{.format}', params),
    },
    formOfPayments: {
      show: params => url('/admin/form_of_payments/{id}{.format}', params),
    },
    invoices: {
      travellerInvoices: params =>
        url(
          '/admin/invoices/grouped_by_order{.format}{?q,state,page,per_page,column,direction,departure_starts_at,departure_ends_at,arrival_starts_at,arrival_ends_at,due_date_starts_at,due_date_ends_at,booking_date_starts_at,booking_date_ends_at,owner_ids[]*,traveller_ids[]*,organization_ids[]*,channels[]*}',
          params,
        ),
      owners: params => url('/admin/invoices/owners{.format}', params),
      channels: params => url('/admin/invoices/channels{.format}', params),
      organizations: params => url('/admin/invoices/organizations{.format}', params),
      travellers: params => url('/admin/invoices/travellers{.format}', params),
      show: params => url('/admin/invoices/{id}{.format}', params),
      changes: params =>
        url(
          '/admin/invoices/{id}/changes{.format}{?received_amount,received_account_id,transaction_fee_amount,transaction_fee_account_id,write_off,write_off_account_id,cancel}',
          params,
        ),
      updateTravellerInvoice: params => url('/admin/invoices{.format}', params),
      list: params =>
        url(
          '/admin/invoices{.format}{?q,page,state,column,direction,created_at_starts,created_at_ends,due_date_starts,due_date_ends,paid_at_starts,paid_at_ends,cancelled_at_starts,cancelled_at_ends,filter}',
          params,
        ),
      remind: params => url('/admin/invoices/{id}/remind{.format}', params),
      cancel: params => url('/admin/invoices/{id}/cancel{.format}', params),
      payments: {
        create: params => url('/admin/invoices/{invoiceId}/payments{.format}', params),
      },
      sendInvoiceMail: params => url('/admin/invoices/{id}/send_invoice_mail{.format}', params),
    },
    supplierInvoices: {
      orders: params =>
        url(
          '/admin/supplier_invoices/orders{.format}{?q,state,page,per_page,column,direction,departure_starts_at,departure_ends_at,arrival_starts_at,arrival_ends_at,due_date_starts_at,due_date_ends_at,booking_date_starts_at,booking_date_ends_at,owner_ids[]*,charging_supplier_ids[]*}',
          params,
        ),
    },
    channels: {
      list: params =>
        url('/admin/channels{.format}{?q,page,per_page,column,direction,archived}', params),
      destroy: params => url('/admin/channels/{channelId}{.format}', params),
      update: params => url('/admin/channels/{channelId}{.format}', params),
      create: params => url('/admin/channels{.format}', params),
      show: params => url('/admin/channels/{channelId}{.format}', params),
    },
    products: {
      list: params =>
        url('/admin/products{.format}{?q,page,per_page,column,direction,archived,filter}', params),
      create: params => url('/admin/products{.format}', params),
      edit: params => url('/admin/products/{productId}/edit{.format}', params),
      organizations: params => url('/admin/products/{productId}/organizations{.format}', params),
      interests: params => url('/admin/products/{productId}/interests{.format}', params),
      update: params => url('/admin/products/{productId}{.format}', params),
      destroy: params => url('/admin/products/{productId}{.format}', params),
      vatMaster: params => url('/admin/products/vat_masters{.format}', params),
      available: params => url('/admin/products/available{.format}', params),
    },
    productCategories: {
      list: params => url('/admin/product_categories{.format}{?q}', params),
      destroy: params => url('/admin/product_categories/{id}{.format}', params),
      create: params => url('/admin/product_categories{.format}', params),
      update: params => url('/admin/product_categories/{productCategoryID}{.format}', params),
    },
    autoQueueHandlingTask: {
      list: params =>
        url('/admin/auto_queue_handling_tasks{.format}{?active,page,per_page}', params),
      create: params => url('/admin/auto_queue_handling_tasks{.format}', params),
      edit: params => url('/admin/auto_queue_handling_tasks/{id}/edit{.format}', params),
      update: params => url('/admin/auto_queue_handling_tasks/{id}{.format}', params),
      destroy: params => url('/admin/auto_queue_handling_tasks/{id}{.format}', params),
      show: params => url('/admin/auto_queue_handling_tasks/{id}{.format}', params),
      activate: params => url('/admin/auto_queue_handling_tasks/{id}/activate{.format}', params),
      accountIdentifiers: params =>
        url('/admin/auto_queue_handling_tasks/account_identifier{.format}{?channel}', params),
    },
    emailAddresses: {
      create: params => url('/admin/email_addresses{.format}', params),
      update: params => url('/admin/email_addresses/{emailId}{.format}', params),
      delete: params => url('/admin/email_addresses/{emailId}{.format}', params),
    },
    positions: {
      list: params => url('/admin/organizations/{organizationId}/positions{.format}', params),
    },
    ibe: {
      edit: params => url('/admin/settings/ibe/edit{.format}', params),
      update: params => url('/admin/settings/ibe{.format}', params),
    },
    recommendation: {
      list: params => url('/admin/settings/ibe/testimonials{.format}', params),
      create: params => url('/admin/settings/ibe/testimonials{.format}', params),
      show: params => url('/admin/settings/ibe/testimonials/{recommendationId}{.format}', params),
      update: params => url('/admin/settings/ibe/testimonials/{recommendationId}{.format}', params),
      destroy: params =>
        url('/admin/settings/ibe/testimonials/{recommendationId}{.format}', params),
    },
    landingSlider: {
      list: params => url('/admin/settings/ibe/landing_sliders{.format}{?page}', params),
      create: params => url('/admin/settings/ibe/landing_sliders{.format}', params),
      edit: params => url('/admin/settings/ibe/landing_sliders/{sliderId}/edit{.format}', params),
      show: params => url('/admin/settings/ibe/landing_sliders/{sliderId}{.format}', params),
      update: params => url('/admin/settings/ibe/landing_sliders/{sliderId}{.format}', params),
      destroy: params => url('/admin/settings/ibe/landing_sliders/{sliderId}{.format}', params),
    },
    blackListedBookings: {
      list: params =>
        url(
          '/admin/black_listed_bookings{.format}{?auto_queue_handling_task_id,imported,q,page,per_page}',
          params,
        ),
      reset: params => url('/admin/black_listed_bookings/{id}/reset{.format}', params),
    },
    carLoyaltyPrograms: {
      list: params => url('/admin/car_loyalty_programs{.format}{?person_id}', params),
      create: params => url('/admin/car_loyalty_programs{.format}', params),
      update: params => url('/admin/car_loyalty_programs/{id}{.format}', params),
      delete: params => url('/admin/car_loyalty_programs/{id}{.format}', params),
    },
    hotelLoyaltyPrograms: {
      list: params => url('/admin/hotel_loyalty_programs{.format}{?person_id}', params),
      create: params => url('/admin/hotel_loyalty_programs{.format}', params),
      update: params => url('/admin/hotel_loyalty_programs/{id}{.format}', params),
      delete: params => url('/admin/hotel_loyalty_programs/{id}{.format}', params),
    },
    ibeSettings: {
      show: params => url('/admin/settings/ibe{.format}', params),
      edit: params => url('/admin/settings/ibe/edit{.format}', params),
      update: params => url('/admin/settings/ibe{.format}', params),
    },
    searchInputConfiguration: {
      edit: params => url('/admin/settings/ibe/search_input_configurations/edit{.format}', params),
      update: params => url('/admin/settings/ibe/search_input_configurations{.format}', params),
    },
    suppliers: {
      list: params =>
        url('/admin/suppliers{.format}{?q,page,per_page,column,direction,archived}', params),
      destroy: params => url('/admin/suppliers/{supplierId}{.format}', params),
      activate: params => url('/admin/suppliers/{supplierId}/activate{.format}', params),
      update: params => url('/admin/suppliers/{supplierId}{.format}', params),
      create: params => url('/admin/suppliers{.format}', params),
      show: params => url('/admin/suppliers/{supplierId}{.format}', params),
      supplierList: () => url('/admin/suppliers/supplier_list{.format}'),
    },
    vatMasters: {
      list: params => url('/admin/vat_masters{.format}{?page,archived}', params),
      create: params => url('/admin/vat_masters{.format}', params),
      edit: params => url('/admin/vat_masters/{id}/edit{.format}', params),
      update: params => url('/admin/vat_masters/{id}{.format}', params),
    },
    margins: {
      list: params => url('/admin/margins{.format}{?page,archived}', params),
      create: params => url('/admin/margins{.format}', params),
      edit: params => url('/admin/margins/{id}/edit{.format}', params),
      update: params => url('/admin/margins/{id}{.format}', params),
    },
    fees: {
      list: params => url('/admin/fees{.format}{?page,archived}', params),
      create: params => url('/admin/fees{.format}', params),
      edit: params => url('/admin/fees/{id}/edit{.format}', params),
      update: params => url('/admin/fees/{id}{.format}', params),
      options: params => url('/admin/fees/fee_options{.format}', params),
    },
    remarkTemplates: {
      list: params => url('/admin/templates/remarks{.format}', params),
      create: params => url('/admin/templates/remarks{.format}', params),
      delete: params => url('/admin/templates/remarks/{id}', params),
      edit: params => url('/admin/templates/remarks/{remarkTemplateID}/edit{.format}', params),
      update: params => url('/admin/templates/remarks/{remarkTemplateID}{.format}', params),
    },
    invoiceTemplates: {
      create: params => url('/admin/templates/invoices{.format}', params),
      preview: params => url('/admin/templates/invoices/preview{.format}', params),
      show: params => url('/admin/templates/invoices/{invoiceTemplateId}{.format}', params),
      update: params => url('/admin/templates/invoices/{invoiceTemplateId}{.format}', params),
    },
    tenantSettings: {
      show: params => url('/admin/settings{.format}', params),
      verify: params => url('/admin/settings/verify{.format}', params),
      reverify: params => url('/admin/settings/reverify{.format}', params),
    },
    staticPages: {
      create: params => url('/admin/settings/ibe/{staticPageType}{.format}', params),
      update: params => url('/admin/settings/ibe/{staticPageType}{.format}', params),
      edit: params => url('/admin/settings/ibe/{staticPageType}/edit', params),
      delete: params => url('/admin/settings/ibe/{staticPageType}{.format}', params),
      show: params => url('/admin/settings/ibe/{staticPageType}{.format}', params),
    },
    textElementTemplates: {
      list: params => url('/admin/templates/text_elements{.format}', params),
      create: params => url('/admin/templates/text_elements{.format}', params),
      delete: params => url('/admin/templates/text_elements/{id}', params),
      show: params => url('/admin/templates/text_elements/{textElementID}{.format}', params),
      update: params => url('/admin/templates/text_elements/{textElementID}{.format}', params),
    },
    todoListTemplates: {
      list: params => url('/admin/todo_list_templates{.format}', params),
      create: params => url('/admin/todo_list_templates{.format}', params),
      show: params => url('/admin/todo_list_templates/{id}{.format}', params),
      update: params => url('/admin/todo_list_templates/{id}{.format}', params),
      itemMatchingScopes: params =>
        url('/admin/todo_list_templates/item_matching_scopes{.format}', params),
      applicabilityScopes: params =>
        url('/admin/todo_list_templates/applicability_scopes{.format}', params),
      autoAddRules: params => url('/admin/todo_list_templates/auto_add_rules{.format}', params),
      priorities: params => url('/admin/todo_list_templates/priorities{.format}', params),
    },
    paymentGateway: {
      list: params => url('/admin/settings/payment/gateways{.format}', params),
      edit: params => url('/admin/settings/payment/gateways/{gatewayID}/edit{.format}', params),
      update: params =>
        url('/admin/settings/payment/gateways/{gatewayID}/{gatewayType}{.format}', params),
      create: params => url('/admin/settings/payment/gateways/{gatewayType}{.format}', params),
      delete: params => url('/admin/settings/payment/gateways/{id}', params),
    },
    paymentMethods: {
      update: params => url('/admin/settings/payment{.format}', params),
      show: params => url('/admin/settings/payment/{type}{.format}', params),
    },
    blogs: {
      list: params => url('/admin/settings/ibe/blog_posts{.format}{?page,per_page}', params),
      show: params => url('/admin/settings/ibe/blog_posts/{id}{.format}', params),
      create: params => url('/admin/settings/ibe/blog_posts{.format}', params),
      update: params => url('/admin/settings/ibe/blog_posts/{id}{.format}', params),
      delete: params => url('/admin/settings/ibe/blog_posts/{id}{.format}', params),
      authors: params => url('/admin/settings/ibe/blog_posts/authors{.format}', params),
    },
    globalPolicies: {
      list: params => url('/admin/policies{.format}{?q,page,column,direction}', params),
      edit: params => url('/admin/policies/{policyId}/edit{.format}', params),
      update: params => url('/admin/policies/{policyId}{.format}', params),
      create: params => url('/admin/policies{.format}', params),
      organizationPolicies: params =>
        url('/admin/policies/{policyId}/organization_policies{.format}', params),
    },
    branches: {
      list: params => url('/admin/branches{.format}{?page}', params),
      create: params => url('/admin/branches{.format}', params),
      show: params => url('/admin/branches/{branchId}{.format}', params),
      update: params => url('/admin/branches/{branchId}{.format}', params),
    },
    approvals: {
      requests: {
        items: params => url('/admin/approvals/requests/{requestId}/items{.format}', params),
        cancel: params => url('/admin/approvals/requests/{requestId}', params),
        redirectToShowPage: params =>
          url('/admin/approvals/requests/{id}/redirect_to_request{.format}', params),
      },
      confirmations: {
        items: params =>
          url('/admin/approvals/confirmations/{confirmationId}/items{.format}', params),
        approve: params => url('/admin/approvals/confirmations/{id}/approve{.format}', params),
        deny: params => url('/admin/approvals/confirmations/{id}/deny{.format}', params),
        redirectToShowPage: params =>
          url('/admin/approvals/confirmations/{id}/redirect_to_confirmation{.format}', params),
      },
    },
    preselectTravelersProperties: params =>
      url('/admin/people/{id}/preselected_traveler_properties{.format}', params),
    orderDestinations: {
      list: params => url('/admin/orders/destinations{.format}{?q}', params),
    },
    travelers: {
      list: params => url('/admin/travelers{.format}{?order_item_id}', params),
      create: params => url('/admin/travelers{.format}', params),
      update: params => url('/admin/travelers/{id}{.format}', params),
      show: params => url('/admin/travelers/{id}{.format}', params),

      identificationDocuments: {
        list: params =>
          url('/admin/travelers/{travelerId}/traveler_identification_documents{.format}', params),
        create: params =>
          url('/admin/travelers/{travelerId}/traveler_identification_documents{.format}', params),
      },
      frequentFlyerNumbers: {
        list: params =>
          url('/admin/travelers/{travelerId}/traveler_frequent_flyer_numbers{.format}', params),
        create: params =>
          url('/admin/travelers/{travelerId}/traveler_frequent_flyer_numbers{.format}', params),
      },
      phoneNumbers: {
        list: params =>
          url('/admin/travelers/{travelerId}/traveler_phone_numbers{.format}', params),
        create: params =>
          url('/admin/travelers/{travelerId}/traveler_phone_numbers{.format}', params),
      },
      emailAddresses: {
        list: params =>
          url('/admin/travelers/{travelerId}/traveler_email_addresses{.format}', params),
        create: params =>
          url('/admin/travelers/{travelerId}/traveler_email_addresses{.format}', params),
      },
    },
    appcuesWorkflow: {
      fetch: params => url('/api/appcues_workflows{?tracking_path}', params),
      create: params => url('/api/appcues_workflows', params),
      update: params => url('/api/appcues_workflows/{id}', params),
    },
  },

  validations: {
    phoneNumber: params => url('/validate/phone_number{.format}{?number,country_code}', params),
    travelerEmail: params =>
      url('/validate/validate_traveler_email{.format}{?email,traveler_id}', params),
  },

  api: {
    airports: params => url('/api/airports{?q,top}', params),
    airport: params => url('/api/airports/{code}', params),

    airlines: params => url('/api/airlines{?q}', params),
    airline: params => url('/api/airlines/{code}', params),
    country: params => url('/api/countries/{code}', params),
    aircraftTypes: params => url('/api/aircraft_types{?q}', params),
    aircraftType: params => url('/api/aircraft_types/{code}', params),

    countries: params => url('/api/countries', params),
    phoneCountryCodes: params => url('/api/countries/phone_codes', params),
    nationalities: params => url('/api/nationalities', params),
    liveFeed: params => url('/api/live_feed', params),
    settings: () => url('/api/settings'),

    approvalRequests: {
      create: params => url('/api/approvals/requests', params),
      fetch: params =>
        url(
          `/api/approvals/requests/{approvalRequestId}{?${callbackParamsTemplate}}`,
          injectCallbackParams(params),
        ),
      request: {
        redirectUrl: {
          agent: params => url('/admin/people/{personId}{?requestTab,tab}', params),
          layman: params => url('/approvals/requests/{requestId}', params),
        },
      },
      redirectUrl: (approvalRequest, laymanMode) => {
        if (!laymanMode) {
          return routes.api.approvalRequests.request.redirectUrl.agent({
            personId: approvalRequest.requestorId,
            requestTab: 'outgoing',
            tab: 'approval_requests',
          });
        }
        return routes.api.approvalRequests.request.redirectUrl.layman({
          requestId: approvalRequest.id,
        });
      },
    },

    deals: {
      create: params => url('/api/deals', params),
      favourite: params => url('/api/deals/{dealId}/favourite', params),
      fetch: params => url('/api/deals/{dealId}', params),
      edit: params => url('/admin/settings/ibe/deals/{dealId}/edit', params),
      redirectUrl: deal => {
        if (deal.person && deal.person.id) {
          return routes.admin.people.show({
            personId: deal.person.id,
            format: 'html',
            tab: 'offers',
          });
        }
        return routes.api.deals.edit({ dealId: deal.id });
      },
    },

    flights: {
      createSearch: () => '/api/flights/searches',
      similarSearch: () => '/api/flights/searches/similar',
      searchResultsFilterByFlightNumbers: params => url('/api/flights/searches/{searchId}', params),
      searchResults: (params, tripCount = null) => {
        const filterParams = [
          'max_price',
          'min_price',
          'airlines[]*',
          'stopover_counts[]*',
          'caterings[]*',
          'baggage[]*',
          'multi_ticket[]*',
          'sort_by',
        ];
        if (tripCount) {
          for (let tripId = 1; tripId <= tripCount; tripId += 1) {
            ['trip_duration_minutes', 'departure_times', 'arrival_times', 'prices'].forEach(
              field => {
                filterParams.push(`${field}[${tripId}][min]`);
                filterParams.push(`${field}[${tripId}][max]`);
              },
            );
          }
        }
        return url(
          `/api/flights/searches/{searchId}/trips/{tripId}{?selected_flight_groups[]*,${filterParams}}`,
          params,
        );
      },
      searchParams: params => [url('/api/flights/searches/{searchId}', params)],
      matrixes: params => {
        const tripCount = Object.keys(params).filter(param => param.match(/flight_groups/)).length;
        const flightGroupsParams = [];
        for (let tripId = 1; tripId <= tripCount; tripId += 1) {
          flightGroupsParams.push(`flight_groups[${tripId}]`);
        }
        return url(
          `/api/flights/searches/{searchId}/matrixes{?${flightGroupsParams},selected[]*}`,
          params,
        );
      },
      fareBasisInfo: params => url('/api/flights/results/{resultId}/fare_basis_info', params),
      resultServices: params => url('/api/flights/results/{resultId}/services', params),
      fetchService: params => url('/api/flights/services/{serviceId}', params),
      exchange: {
        create: params => url('/api/bookings/{bookingIdentifier}/exchanges', params),
        fetchQuote: params =>
          url('/api/flights/results/{resultId}/exchange_quote{?booking_id}', params),
      },
      emd: {
        issue: params => url('/api/orders/{orderId}/order_items/{orderItemId}/emds', params),
      },
      seat: {
        fetchMap: params =>
          url('/api/orders/{orderId}/order_items/{orderItemId}/seat_maps', params),
        add: params => url('/api/orders/{orderId}/order_items/{orderItemId}/seats', params),
        postBookingAction: {
          create: params => url('/api/order_items/{orderItemId}/post_booking_actions', params),
          fetch: params => url('/api/post_booking_actions/{postBookingActionId}', params),
        },
      },
    },

    hotels: {
      createSearch: () => '/api/hotels/searches',
      searchResults: params =>
        url(
          '/api/hotels/searches/{searchId}{?name,rating[min],rating[max],stars[]*,price[min],price[max],price_ranges,refundable[]*,sort_by}',
          params,
        ),
      result: params =>
        url(
          `/api/hotels/results/{resultId}{?${callbackParamsTemplate}}`,
          injectCallbackParams(params),
        ),
    },

    cars: {
      vendors: params => url('/api/cars/vendors{?q}', params),
      createSearch: () => '/api/cars/searches',
      searchResults: params =>
        url(
          '/api/cars/searches/{searchId}{?price[min],price[max],acriss_code,categories[]*,vendors[]*,sort_by}',
          params,
        ),
    },
    carts: {
      createCart: () => '/api/carts',
      fetchCart: params =>
        url(
          `/api/carts/{cartId}{?show_travel_plan,${callbackParamsTemplate}}`,
          injectCallbackParams(params),
        ),
      cartWalletDetails: params => url('/api/carts/{cartId}/welltravel_wallet', params),
      all: params =>
        url(`/api/carts/{?page,per_page,${callbackParamsTemplate}}`, injectCallbackParams(params)),
      checkAvailability: params => url('/api/carts/{cartId}/check_availability', params),
      checkAllItemsAvailability: params => url('/api/carts/{cartId}/availability', params),
      updateCart: params => url('/api/carts/{cartId}', params),
      share: params => url('/api/carts/{cartId}/share', params),
      prepareCartForExternalPayment: params =>
        url('/api/carts/{cartId}/prepare_for_booking', params),
      fetchVisaAdvisory: params =>
        url('/api/carts/{cartId}/visa_advisory{?citizenship_country_code}', params),
      paymentMethod: params =>
        url(
          `/api/carts/{cartId}/payment_method{?${callbackParamsTemplate}}`,
          injectCallbackParams(params),
        ),
      items: {
        addCar: params => url('/api/carts/{cartId}/items/cars', params),
        addFlight: params => url('/api/carts/{cartId}/items/flights', params),
        addHotel: params => url('/api/carts/{cartId}/items/hotels', params),
        addStaticProduct: params => url('/api/carts/{cartId}/items/static_products', params),
        book: params => url('/api/carts/{cartId}/items/{itemId}/book', params),
        ensureService: params => url('/api/carts/{cartId}/items/services/{serviceId}', params),
        setRemark: params => url('/api/carts/{cartId}/items/set_remarks', params),
        addQueuePlacement: params => url('/api/carts/{cartId}/items/queue_placement', params),
        addSeat: params => url('/api/carts/{cartId}/items/seats', params),
        removeItem: params =>
          url(
            `/api/carts/{cartId}/items/{itemId}{?${callbackParamsTemplate},manage_product}`,
            injectCallbackParams(params),
          ),
        confirm: params => url('/api/carts/{cartId}/items/{itemId}/confirm', params),
        update: params => url('/api/carts/{cartId}/items/{itemId}', params),
        justification: params => url('/api/carts/{cartId}/items/{itemId}/justification', params),
        setFormOfPayment: {
          add: params => url('/api/carts/{cartId}/items/{itemId}/form_of_payment', params),
          fetch: params => url('/api/carts/{cartId}/items/{itemId}/form_of_payment', params),
        },
      },
      resetFormOfPayment: params => url('/api/carts/{cartId}/form_of_payments', params),
      fees: {
        add: params => url('/api/carts/{cartId}/fees', params),
        remove: params => url('/api/carts/{cartId}/fees/{feeId}', params),
        setQuantity: params => url('/api/carts/{cartId}/fees/{feeId}', params),
      },
      costCenters: {
        add: params => url('/api/carts/{cartId}/cost_centers', params),
        edit: params => url('/api/carts/{cartId}/cost_centers', params),
        remove: params => url('/api/carts/{cartId}/cost_centers', params),
      },
      legalPapers: {
        fetch: params =>
          url(
            `/api/carts/{cartId}/legal_papers{?${callbackParamsTemplate}}`,
            injectCallbackParams(params),
          ),
      },
      book: params => url('/api/carts/{cartId}/book', params),
      bookingStatus: params =>
        url(
          `/api/carts/{cartId}/booking_status{?${callbackParamsTemplate}}`,
          injectCallbackParams(params),
        ),
      emailRecipients: {
        add: params => url('/api/carts/{cartId}/email_recipients', params),
        fetch: params => url('/api/carts/{cartId}/email_recipients', params),
      },
      people: {
        setPeopleAndAddDefaultCostCenter: params =>
          url(
            `/api/carts/{cartId}/people/{sequence}{?${callbackParamsTemplate}}`,
            injectCallbackParams(params),
          ),
        selectCartPeopleAndAddDefaultCostCentter: params =>
          url(
            `/api/carts/{cartId}/people/{sequence}/select{?${callbackParamsTemplate}}`,
            injectCallbackParams(params),
          ),
        fetch: params =>
          url(
            `/api/carts/{cartId}/people{?${callbackParamsTemplate}}`,
            injectCallbackParams(params),
          ),
      },
      voucher: {
        contentStatus: params => url('/api/carts/{cartId}/voucher_content_status{?number}', params),
      },
      expectedApprovers: params => url('/api/carts/{cartId}/expected_approvers', params),
    },
    costCenters: {
      accountingTypeValues: params => url('/api/cost_centers/{accountingTypeId}', params),
    },
    orders: {
      create: params => url('/api/orders', params),
      remove: params => url('/api/orders/{id}/{state}', params),
      expectedApprovers: params => url('/api/orders/{id}/expected_approvers', params),
      costCenters: {
        add: params => url('/api/orders/{orderId}/cost_centers', params),
        update: params => url('/api/orders/{orderId}/cost_centers', params),
        remove: params => url('/api/orders/{orderId}/cost_centers', params),
      },
    },
    fetchJourneyElement: params => url('/api/journey_elements/{type}s/{id}', params),
    fetchStaticProducts: params => url('/api/products{?q,locations[]*}', params),
    fetchAStaticProduct: params => url('/api/products/{id}', params),
    searchPlaces: params => url('/api/places{?q,suppliers[]*}', params),
    fetchPlace: params => url('/api/places/{id}', params),
    fetchLocation: params => url('/api/locations/{locationId}', params),
    fetchGeoLocation: params =>
      url('/api/locations/address?latitude={latitude}&longitude={longitude}', params),
    fees: params => url(`/api/fees{?all,${callbackParamsTemplate}}`, injectCallbackParams(params)),
    maskedCreditCards: {
      create: params => url('/api/masked_credit_cards', params),
    },
    customers: {
      search: params =>
        url(`/api/customers{?q,${callbackParamsTemplate}}`, injectCallbackParams(params)),
      currentCustomer: params =>
        url(`/api/customer{?${callbackParamsTemplate}}`, injectCallbackParams(params)),
      updateCustomer: params => url('/api/customer', params),
      accountingTypes: {
        all: params => url('/api/people/{customerId}/accounting_types', params),
      },
    },
    paymentGateway: {
      fetch: params =>
        url(
          '/api/payment/{paygateType}?cart_id={cartId}&base_url={baseUrl}&redirect_to={redirectTo}&payment_method={paymentMethod}&popup={popup}',
          params,
        ),
    },
    reports: {
      travelActivity: params => url('/admin/reporting/travel_activities_kpi.json', params),
      toBeInvoiced: params =>
        url('/admin/reporting/to_be_invoiced_kpi.json?source={source}', params),
      openInvoice: params => url('/admin/reporting/open_invoice_kpi.json?source={source}', params),
      orders: params => url('/admin/reporting/order_kpis.json?scope={scope}', params),
      turnover: params => url('/admin/reporting/turnover_kpis.json', params),
      turnoverHistory: params =>
        url('/admin/reporting/turnover_history_kpis.json?year={year}', params),
      bookingHistory: params =>
        url('/admin/reporting/booking_history_kpis.json?year={year}', params),
      bookings: params => url('/admin/reporting/booking_kpis.json?source={source}', params),
    },
    shareLocation: params =>
      url(
        '/api/location?latitude={latitude}&longitude={longitude}&last_location_time={lastLocationTime}',
        params,
      ),
  },

  public: {
    orders: {
      list: params => url('/orders{.format}{?q,page,person_id,scope,column,direction}', params),
      travellers: params => url('/orders/{orderId}/travellers{.format}', params),
      show: params => url('/orders/{id}{.format}', params),
      vouchers: params =>
        url(
          '/orders/{orderId}/vouchers{.format}{?q,title,destination,page,column,direction,updated_at}',
          params,
        ),
      items: params => url('/orders/{id}/items{.format}', params),
      fees: params => url('/orders/{id}/fees{.format}', params),
    },
    order: params => url('/orders/{id}{.format}', params),
    cartPage: params => url('/c/{id}', params),
    bookingPage: params => url('/o/{id}/booking{.format}', params),
    orderInvoices: params =>
      url(
        '/orders/{relatedModelId}/invoices{.format}{?q,tab,page,per_page,column,direction}',
        params,
      ),
    deals: params =>
      url(
        '/deals/catalogue{.format}{?destination,categories[]*,minPriceRange,maxPriceRange,page,per_page}',
        params,
      ),
    offerEnquiries: params => url('/offer_enquiries{.format}', params),
    deal: params => url('/d/{id}', params),
    dealDetails: params => url('/deals/{id}/details', params),
    topDeals: params => url('/deals/top_deals{.format}', params),
    mostPopularDeal: params => url('/deals/most_popular_deal{.format}', params),
    fetchFavouriteDeals: params => url('/deals/favourites{.format}', params),
    fetchFriendsFavouriteDeals: params => url('/deals/friends_favourites{.format}', params),

    approvals: {
      requests: {
        list: params => url('/approvals/requests{.format}{?scope,tab,page}', params),
        show: params => url('/approvals/requests/{id}{.format}', params),
        exchange: params => url('/approvals/requests/{id}{.format}', params),
        items: params => url('/approvals/requests/{requestId}/items{.format}', params),
        cancel: params => url('/approvals/requests/{requestId}{.format}', params),
      },
      confirmations: {
        list: params => url('/approvals/confirmations{.format}{?scope,tab,page}', params),
        show: params => url('/approvals/confirmations/{id}{.format}', params),
        items: params => url('/approvals/confirmations/{confirmationId}/items{.format}', params),
        approve: params => url('/approvals/confirmations/{id}/approve{.format}', params),
        deny: params => url('/approvals/confirmations/{id}/deny{.format}', params),
      },
    },
    authentication: {
      people: {
        create: params => url('/sign_up{.format}', params),
      },
      sessions: {
        create: params => url('/session{.format}', params),
      },
      passwords: {
        create: params => url('/passwords{.format}', params),
        edit: params => url('/people/{userId}/password/edit{.format}', params),
        update: params => url('/people/{userId}/password{.format}', params),
      },
    },
    frequentFlyerNumbers: {
      create: params => url('/frequent_flyer_numbers{.format}', params),
      list: params => url('/frequent_flyer_numbers{.format}', params),
      update: params => url('/frequent_flyer_numbers/{id}{.format}', params),
      delete: params => url('/frequent_flyer_numbers/{id}{.format}', params),
    },
    people: {
      show: params => url('/account{.format}', params),
      update: params => url('/account{.format}', params),
      updatePassword: params => url('/account/update_password{.format}', params),
      profilePicture: params => url('/profile_picture{.format}', params),
      countryOptions: params => url('/account/country_options', params),
      nationalityOptions: params => url('/account/nationality_options', params),
      users: params => url('/account/users{.format}{?q,person_ids[]*}', params),
    },
    identificationDocuments: {
      create: params => url('/identification_documents{.format}', params),
      list: params => url('/identification_documents{.format}', params),
      update: params => url('/identification_documents/{documentId}{.format}', params),
      delete: params => url('/identification_documents/{documentId}{.format}', params),
    },
    visaNumbers: {
      create: params => url('/visa_numbers{.format}', params),
      list: params => url('/visa_numbers{.format}', params),
      update: params => url('/visa_numbers/{visaNumberID}{.format}', params),
      delete: params => url('/visa_numbers/{visaNumberID}{.format}', params),
    },
    emergencyContacts: {
      create: params => url('/emergency_contacts{.format}', params),
      list: params => url('/emergency_contacts{.format}', params),
      update: params => url('/emergency_contacts/{id}{.format}', params),
      delete: params => url('/emergency_contacts/{id}{.format}', params),
    },
    carLoyaltyPrograms: {
      list: params => url('/car_loyalty_programs{.format}', params),
      create: params => url('/car_loyalty_programs{.format}', params),
      update: params => url('/car_loyalty_programs/{id}{.format}', params),
      delete: params => url('/car_loyalty_programs/{id}{.format}', params),
    },
    hotelLoyaltyPrograms: {
      list: params => url('/hotel_loyalty_programs{.format}', params),
      create: params => url('/hotel_loyalty_programs{.format}', params),
      update: params => url('/hotel_loyalty_programs/{id}{.format}', params),
      delete: params => url('/hotel_loyalty_programs/{id}{.format}', params),
    },
    socialMedia: {
      create: params => url('/social_media{.format}', params),
      list: params => url('/social_media{.format}', params),
      update: params => url('/social_media/{socialMediaID}{.format}', params),
      delete: params => url('/social_media/{socialMediaID}{.format}', params),
    },
    phoneNumbers: {
      create: params => url('/phone_numbers{.format}', params),
      update: params => url('/phone_numbers/{phoneNumberId}{.format}', params),
      delete: params => url('/phone_numbers/{phoneNumberId}{.format}', params),
    },
    travellerAddresses: {
      create: params => url('/traveler_addresses{.format}', params),
      update: params => url('/traveler_addresses/{addressId}{.format}', params),
      delete: params => url('/traveler_addresses/{addressId}{.format}', params),
    },
    emailAddresses: {
      create: params => url('/email_addresses{.format}', params),
      update: params => url('/email_addresses/{emailId}{.format}', params),
      delete: params => url('/email_addresses/{emailId}{.format}', params),
    },
    travelArrangers: {
      list: params => url('/travel_arrangers{.format}{?state}', params),
      accept: params =>
        url('/travel_arrangement_settings/clients/accept_multiple{.format}', params),
      decline: params =>
        url('/travel_arrangement_settings/clients/decline_multiple{.format}', params),
      switch: params => url('/travel_arranger_session{.format}', params),
      switchBack: params => url('/travel_arranger_session/{id}{.format}', params),
    },
    travelArrangerSettings: {
      arrangers: {
        list: params =>
          url(
            '/travel_arrangement_settings/arrangers{.format}{?state,q,page,per_page,column,direction}',
            params,
          ),
        activate: params =>
          url('/travel_arrangement_settings/arrangers/{travelArrangerSettingID}{.format}', params),
        deactivate: params =>
          url('/travel_arrangement_settings/arrangers/{travelArrangerSettingID}{.format}', params),
        create: params => url('/travel_arrangement_settings/arrangers{.format}', params),
        updateLevel: params =>
          url(
            '/travel_arrangement_settings/arrangers/{travelArrangerSettingId}/update_level{.format}',
            params,
          ),
        show: params =>
          url('/travel_arrangement_settings/arrangers/{travelArrangerSettingId}{.format}', params),
      },
      clients: {
        list: params =>
          url(
            '/travel_arrangement_settings/clients{.format}{?state,q,page,per_page,column,direction}',
            params,
          ),
        deactivate: params =>
          url('/travel_arrangement_settings/clients/{travelArrangerSettingID}{.format}', params),
      },
    },
    orderDocuments: {
      list: params =>
        url('/orders/{orderId}/attachments{.format}{?q,page,column,direction,tab}', params),
      create: params => url('/orders/{orderId}/attachments{.format}', params),
      show: params => url('/attachments/{attachmentId}{.format}', params),
      update: params => url('/attachments/{attachmentId}{.format}', params),
      delete: params => url('/attachments/{attachmentId}{.format}', params),
    },
    orderAccountings: {
      list: params => url('/orders/{orderId}/cost_centers{.format}{?page}', params),
      create: params => url('/orders/{orderId}/cost_centers{.format}', params),
      edit: params => url('/cost_centers/{accountingId}/edit{.format}?order_id={orderId}', params),
      update: params => url('/cost_centers/{accountingId}{.format}', params),
      delete: params => url('/cost_centers/{accountingId}{.format}', params),
    },
    blogPosts: {
      list: params => url('/blog/posts{.format}{?page,per_page}', params),
      show: params => url('/blog/posts/{id}', params),
    },
    subscription: {
      create: params => url('/mailing_lists', params),
    },
    orderDestinations: {
      list: params => url('/orders/destinations{.format}{?q}', params),
    },
  },
};

export default routes;
