import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Icon from '@wtag/rcl-icon';

const AgentInfoDetails = ({ country, info, isLoading }) => {
  if (isLoading) {
    return <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} isRounded={false} />;
  }

  const { name: countryName } = country;
  const { name, street, zipCode, city, email, phone, phoneCountryCode } = info;
  const address = [street, zipCode, city, countryName].filter(Boolean).join(', ');
  const phoneWithCountryCode = `+${phoneCountryCode}${phone}`;

  return (
    <Fragment>
      <span className="agent-info__title">{name}</span>
      <span>{address}</span>
      <div className="d-flex-col row-gap-8">
        <div className="d-flex align-center column-gap-8 agent-info__email">
          <Icon name="email" size="normal" />
          <span>{email}</span>
        </div>
        <div className="d-flex align-center column-gap-8">
          <Icon name="call" size="normal" />
          <span>{phoneWithCountryCode}</span>
        </div>
      </div>
    </Fragment>
  );
};

AgentInfoDetails.propTypes = {
  country: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  info: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    phoneCountryCode: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AgentInfoDetails;
