/**
 * Returns the footer text for the cart same flight modal based on the conflict count, quote status, and layman mode.
 *
 * @param {number} conflictCount - The number of conflicts in the cart.
 * @param {boolean} isQuoted - Whether the cart items are quoted.
 * @param {boolean} isLaymanMode - Whether the application is in layman mode.
 *
 * @returns {string} The footer text for the cart same flight modal.
 */
const getCartSameFlightModalFooterText = (conflictCount, isLaymanMode, isQuoted) => {
  if (isQuoted) {
    return I18n.t('cart.multiple_items.remove_alert.quoted', { count: conflictCount });
  }

  if (isLaymanMode) {
    return I18n.t('cart.multiple_items.remove_alert.travel_app', { count: conflictCount });
  }

  return I18n.t('cart.multiple_items.remove_alert.agent');
};

export default getCartSameFlightModalFooterText;
