import { useEffect, useState } from 'react';

const useLocation = () => {
  const [location, setLocation] = useState(window.location);

  const handleLocationChange = () => {
    setLocation({ ...window.location });
  };

  useEffect(() => {
    handleLocationChange();

    window.addEventListener('popstate', handleLocationChange);
    window.addEventListener('hashchange', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.removeEventListener('hashchange', handleLocationChange);
    };
  }, [window.location.href]);

  return location;
};

export default useLocation;
