import { useEffect, useState } from 'react';
import httpClient from 'agentHTTPClient';
import httpV3Client from 'agentHTTPV3Client';
import routes from 'agentRoutes';

const useAgentInfo = () => {
  const [info, setInfo] = useState({});
  const [country, setCountry] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchSettings = async () => {
    setIsLoading(true);

    const { data: agentInfo } = await httpV3Client.get(routes.api.settings());
    const { data: countryInfo } = await httpClient.get(
      routes.api.country({ code: agentInfo.countryCode }),
    );

    setInfo(agentInfo);
    setCountry(countryInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return {
    country,
    info,
    isLoading,
  };
};

export default useAgentInfo;
