const getCartModalRejectionText = (isLaymanMode, isShared, isQuoted) => {
  if (isLaymanMode) {
    return isQuoted
      ? I18n.t('shared.actions.contact_agent')
      : I18n.t('shared.actions.continue_anyway');
  }

  if (isShared && isQuoted) {
    return I18n.t('shared.actions.cancel');
  }

  return I18n.t('shared.actions.continue');
};

export default getCartModalRejectionText;
