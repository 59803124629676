import { FormatDateTime, DATE_FORMAT_YEAR_MONTH_DAY } from '../../../helpers/dateTime';
import { TYPE_FLIGHT } from '../../../helpers/itemTypes';

/**
 * Finds duplicate flight items based on their start date.
 *
 * @param {Array<Object>} items - An array of journey element objects.
 * @param {string} items[].journeyElementType - The type of journey element.
 * @param {string} items[].startsAt - The start date-time of the journey element.
 * @returns {Array<Object>} An array of duplicate flight items.
 *
 * @description
 * This function processes an array of journey elements, filtering for flight types only.
 * It groups flight items by their start date (formatted as YYYY-MM-DD).
 * It then returns an array of all flight items that share a start date with at least one other flight item.
 */

const getDuplicateFlightItems = (items = []) => {
  const flightsByDate = {};

  items.forEach(flightItem => {
    const { journeyElementType, startsAt } = flightItem;

    if (journeyElementType !== TYPE_FLIGHT) {
      return;
    }

    const startsDate = FormatDateTime({
      dateTime: startsAt,
      format: DATE_FORMAT_YEAR_MONTH_DAY,
      displayWithoutConversion: true,
    });

    if (flightsByDate[startsDate]) {
      flightsByDate[startsDate].push(flightItem);
    } else {
      flightsByDate[startsDate] = [flightItem];
    }
  });

  return Object.values(flightsByDate)
    .filter(dateFrequency => dateFrequency.length > 1)
    .flat();
};

export default getDuplicateFlightItems;
