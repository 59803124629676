import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import ProductIcon from 'sharedWebpack/ProductIcon';
import { TYPE_FLIGHT } from 'sharedWebpack/helpers/itemTypes';
import './styles.scss';

const FlightItem = ({ flight, itemNumber }) => {
  const { itemTitle, startsAt } = flight;
  const flightItemTitle = I18n.t('cart.multiple_items.item_title', {
    item_number: itemNumber,
    item_title: itemTitle,
  });

  return (
    <div className="d-flex align-center justify-between flight-item">
      <div className="d-flex align-center column-gap-8">
        <ProductIcon showBGColor={true} productType={TYPE_FLIGHT} />
        <span className="flight-item__title">{flightItemTitle}</span>
      </div>
      <span className="flight-item__date">
        <DateTime dateTime={startsAt} format="shortWithTime" />
      </span>
    </div>
  );
};

FlightItem.propTypes = {
  flight: PropTypes.shape({
    itemTitle: PropTypes.string.isRequired,
    startsAt: PropTypes.string.isRequired,
  }).isRequired,
  itemNumber: PropTypes.number.isRequired,
};

export default FlightItem;
