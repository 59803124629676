import React from 'react';
import { hot } from 'react-hot-loader';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import useAgentInfo from './useAgentInfo';
import AgentInfoDetails from './subcomponents/AgentInfoDetails';
import './styles.scss';

const AgentInfo = () => {
  const { info, country, isLoading } = useAgentInfo();
  const { logo } = info;

  return (
    <div className="d-flex-column row-gap-20">
      <span>{I18n.t('cart.multiple_items.agent_info.sub_title')}</span>
      <div className="agent-info">
        <div className="d-flex w-100 agent-info__logo">
          {isLoading ? (
            <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} isRounded={false} />
          ) : (
            <img src={logo} alt="Tenant Logo" height={24} width={156} />
          )}
        </div>
        <div className="d-flex-col align-baseline row-gap agent-info__details">
          <AgentInfoDetails country={country} info={info} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default hot(module)(AgentInfo);
